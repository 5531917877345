.mochi-team {
  flex-direction: column;
  padding: 3rem 0;
}

.mochi-team > div {
  width: 100%;
}

.title-member {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 32px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 40px;
}

.image-area {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.member-card {
  align-items: center;
  text-align: center;
  padding-right: 15px;
  padding-left: 15px;
}

.member-card > .footer-card {
  display: flex;
  justify-content: center;
  margin-bottom: 56px;
}

.footer-card > div {
  margin: 0 1rem;
}

.footer-card > div > img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.member-card > img {
  width: 264px;
  height: 350px;
  border-radius: 12px;
  object-fit: cover;
}

.member-card > .name {
  margin-top: 24px;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
}

.member-card > .role {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #adb5c1;
}

@media (max-width: 768px) {
  .member-card > .footer-card {
    margin-bottom: 32px;
  }

  .member-card > img {
    height: 200px;
    width: 152px;
  }

  .member-card > .name {
    margin-top: 16px;
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }

  .member-card > .role {
    font-size: 12.9px;
    line-height: 16px;
  }
}
