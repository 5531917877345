@import 'bootstrap/dist/css/bootstrap.min.css';

body {
  background: radial-gradient(123.22% 0% at 100.89% -5.6%, #201d47 0%, #17153a 100%);
  color: rgba(173, 181, 193, 1);
}

.container {
  margin: 0 auto;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.smail-title {
  color: #53d0ec;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 5px;
  text-align: center;
}

.big-title {
  font-size: 38px;
  line-height: 46px;
  margin-bottom: 28px;
  text-transform: capitalize;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
}

.description {
  text-align: center;
  font-style: normal;
  font-weight: normal;
  width: 75%;
  font-size: 16px;
  line-height: 20px;
  color: #adb5c1;
  text-align: center;
}

.text-center {
  text-align: center !important;
}

@media (max-width: 768px) {
  .smail-title {
    font-size: 12px;
    line-height: 16px;
  }

  .big-title {
    margin-bottom: 18px;
    font-size: 24px;
    line-height: 28px;
  }

  .description {
    width: 90%;
    font-size: 16px;
    line-height: 20px;
  }
}
.title-section {
  text-align: center;
  position: relative;
}

.title-section h1 {
  font-weight: bold;
  color: white;
}
.title-section .description-section {
  margin: 0 auto;
  margin-top: 1.5rem;
  max-width: 800px;
}

.padding-0 {
  padding: 0;
}

.partner-card.content {
  padding: 1rem;
  align-items: center;
  height: 120px;
}

.justify-center {
  justify-content: center;
}
.flex {
  display: flex;
}
.cursor-pointer {
  cursor: pointer;
}
.rounded-xl {
  border-radius: 0.75rem;
}

img,
video {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  h1 {
    font-size: 1.6rem;
  }
}
