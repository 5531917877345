/* .mochimarket-logo {
  width: 200px;
  height: 110px;
  padding: 30px;
  background: #17153a;
  background-color: #1b47e20f;
  border-style: solid;
  border-width: 1px;
  border-color: #e4609e;
  border-radius: 12px;
  margin: 50px;
} */

.projects {
  flex-direction: column;
  padding: 3rem 0 8rem 0;
}

.projects > div {
  width: 100%;
}

.image-area {
  display: flex;
  flex-wrap: wrap;
}

.project-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 128px;
  margin-bottom: 50px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 1rem;
}

/* .project-card > img {
  filter: brightness(0) invert(1);
} */

.project-card:hover {
  box-shadow: 0 10px 20px 0 rgb(146 146 146 / 21%);
}

.mochi {
  width: 200px;
}

@media (max-width: 1200px) {
  .project-card {
    padding: 0;
    width: 215px;
    height: 128px;
  }

  .mochi {
    height: 65px;
    width: 200px;
  }
}

@media (max-width: 992px) {
  .project-card {
    padding: 0;
    width: 215px;
    height: 128px;
  }
}

@media (max-width: 767px) {
  .project-card {
    width: 104.21px;
    height: 48.24px;
    margin-bottom: 19px;
  }

  .mochi {
    height: 35px;
    width: 100px;
  }
}
